@import 'variables';
@import 'reset';

#property-press,
html,
body {
    height: 100%;
}

#property-press-main {
    min-height: 100%;
}

h1, h2, h3 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.1;

    @media (min-width: 1000px) {
        font-size: 30px;
    }

    @media (min-width: 1200px) {
        font-size: 40px;
    }
}

textarea,
input {
    color: $black;
    width: 100% !important;
    display: block;
}

input[type="submit"] {
    max-width: 170px;
}

[disabled] {
    opacity: 0.2;
}

.property-press-header {
    height: $header-height + 100px;
    background-size: cover;
    background-position: 50%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    backface-visibility: hidden;

    @media (min-width: 1000px) {
        height: 100vh;
        left: 50%;   
    }
}

.property-press-main {
    padding-top: $header-height;
    text-align: center;
    font-size: 13px;

    @media (min-width: 1000px) {
        padding-top: 0;
        padding-right: 50%;
        font-size: 14px;
    }

    .gform_confirmation_wrapper,
    .gform_ajax_spinner,
    .gfield_required {
        display: none !important;
    }
    
    .gform_wrapper {
        margin: 0;

        .gfield {
            position: relative;
            margin: 0 0 20px !important;
        }
    
        .ginput_container {
            margin-top: 0 !important;
        }

        .gform_footer {
            margin: 0;
            padding: 0;
            text-align: center;
        }

        label {
            display: block !important;
            text-align: center;
            font-weight: 300 !important;

            transform: translate3d(0, 30px, 0);
            transition: 300ms ease;
            font-size: 18px !important;
        }  

        .field-active,
        .field-complete {

            label {
                opacity: 0.5;
                transform: scale(0.75);
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"] {
            border-radius: 0;
            appearance: none;
            border: 0;
            border-bottom: 1px solid $black;
            padding: 0 !important;
            height: 40px;
            text-align: center;
        }

        input[type="submit"] {
            margin: 0 auto !important;
            height: 40px !important;
            appearance: none;
            border: 1px solid $black;
            background: transparent;
            border-radius: 3px;
            font-size: 12px;
            padding: 0 !important;

            transition: 300ms ease;
            will-change: background, color;

            &:focus,
            &:hover {
                background-color: $black;
                color: #fff;
            }
        }
    }
}

.property-press-main-inner {
    background-color: #fff;
    padding: 40px 20px;
    position: relative;

    @media (min-width: 1000px) {
        padding: 40px;
    }
}

.property-press-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    padding: 20px;

    @media (min-width: 1000px) {
        right: 50%;
    }

    img {
        max-height: 60px;
    }
}

.property-press-title,
.property-press-subtitle {
    margin-bottom: 20px;
}

.success-icon {
    height: 60px;
    width: 60px;
    
    stroke-dasharray: 300px;
    stroke-dashoffset: 300px;
    transition: stroke-dashoffset 500ms ease-in-out 2000ms;

    * {
        stroke: $green !important;
    }

    &.success-icon-go {
        stroke-dashoffset: 0;
    }
}

.property-press-confirm {

    li {
        margin: 0;
    }

    a {
        text-decoration: none;
        font-size: 20px;

        svg, span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    > li:first-of-type {
        float: right;
        
        a {
            color: $green;
        }

        svg {
            fill: $green;
        }
    }

    > li:last-of-type {
        float: left;

        a {
            color: $red;
        }

        svg {
            fill: $red;
        }
    }

    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

#property-press-steps {
    display: block;
    min-height: 350px;
    max-width: 580px;
    margin: 0 auto;

    @media (min-width: 1000px) {
        padding-top: 20%;
    }
}

.property-press-step {
    transition: 500ms ease;
}

// #property-press-step-2 {
//     opacity: 0;
// }

#property-press-step-2,
#property-press-step-3,
#property-press-step-4 {
    opacity: 0;
    display: none;
}

.property-press-main-inner {
    transition: 500ms ease;
}

#property-press-map {
    opacity: 0;
    transition: 500ms ease;
    height: $header-height + 100px;

    @media (min-width: 1000px) {
        height: 100vh;
    }
}

[data-current-step="2"] {

    #property-press-map {
        opacity: 1;
    }

    .property-press-main-inner {
        transform: translate3d(0, 100px, 0);

        @media (min-width: 1000px) {
            transform: none;
        }
    }
}

[data-current-step="3"],
[data-current-step="4"] {
    #property-press-map {
        opacity: 1;
    }
}

#property-press-step-2 {
    max-width: 300px;
    margin: 0 auto;
}

@keyframes loadingDots {

    0% {
        transform: translate3d(0, 0px, 0);
        -webkit-transform: translate3d(0, 0px, 0);
    }

    70% {
        transform: translate3d(0, 10px, 0);
        -webkit-transform: translate3d(0, 10px, 0);
    }

    100% {
        transform: translate3d(0, 0px, 0);
        -webkit-transform: translate3d(0, 0px, 0);
    }
}

#property-press-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#333, 0.5);
    opacity: 0;
    transition: 300ms ease;

    @media (min-width: 1000px) {
        left: 50%;
    }

    [data-loading="true"] & {
        opacity: 1;
    }

    > div {
        font-size: 0;
        color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        text-align: center;
        right: 0;
    }

    div > span {
        margin: 0 3px;
        background-color: #fff;
        height: 10px;
        width: 10px;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        backface-visibility: hidden;
        animation: loadingDots 1100ms infinite ease;

        &:nth-child(1) { animation-delay: 0ms; opacity: 1; }
        &:nth-child(2) { animation-delay: -100ms; }
        &:nth-child(3) { animation-delay: -200ms; }
    }
}

.property-press-zillow {
    font-size: 20px;
    margin-bottom: 20px;
}

#property-press-step-4 {

    h1 {
        margin-bottom: 30px;
    }

    p {

        a {
            color: #222;
            font-weight: 700;
        }
    }
}




















